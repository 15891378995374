body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.word-counts {
    align-content: start;
    width: 100%;
    height: 15rem;
    display: inline-block;
    padding: 0.5rem;
    box-sizing: border-box;
}

.progress-summary {
    align-content: start;
    width: 100%;
    height: 8rem;
    display: inline-block;
    padding: 0.5rem;
    box-sizing: border-box;
}

.lesson-summary {
    display: inline-flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 1rem;
}

.lesson-summary .lesson-stat {
    padding: 0.5rem;
}

.progress-summary-alt {
    display: inline-flex;
}

.progress-summary-alt .progress-stat {
    padding: 0.5rem;
}

.value {
    font-weight: bold;
    font-size: 1.5rem;
}
.value-label {
    font-size: 1rem;
    color: lightgray;
}

.app {
    text-align: center;
    display: flex;
    flex-direction: column;
}

.app-logo {
    height: 40vmin;
    pointer-events: none;
}

.app-header {
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.app-header img {
    align-content: flex-end;
    padding: 0;
}

.app-header .app-name {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 1rem;
    margin: 0;
}

.ww-orange {
    color: #fda95e;
}
.ww-blue {
    color: #4ab7b6;
}

.buttons {
    padding:1rem;
}
button {
    background-color: #4ab7b6;
    border: none;
    width: inherit;
    min-width: 10rem;
    border-radius: 4px;
    color: white;
    padding: 0.5rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 0.25rem;
}

button:disabled {
    cursor: not-allowed;
    background-color: #7ab7b6;
    color: lightgray;
}

.word-selection {
    /*padding: 0.5rem;*/
}
.word {
    margin: 0.25rem;
}

input {
    font-size: 1rem;
    font-family: inherit;
    padding: 0.25rem 0.5rem;
    background-color: #fff;
    border: 1px solid #8b8a8b;
    border-radius: 4px;
    width: 100%;
    line-height: 1.25rem;
    min-width: 5rem;
    box-sizing: border-box;
}

input:focus {
    border-color: #fda95e;
    outline: 3px solid transparent;
}

select:focus {
    border-color: #fda95e;
    outline: 3px solid transparent;
}

.lesson-selection {
    margin: 0.25rem;
}

.audio-delay {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0.25rem;
}

.audio-delay .title {
    margin-right: 0.5rem;
    cursor: pointer;
}

.audio-delay select {
    width: 30%;
    padding: 0.25rem;
}


.selection {
    max-width: 14rem;
    margin: auto;
}

select {
    font-size: 1rem;
    font-family: inherit;
    padding: 0.25rem 0.5rem;
    background-color: #fff;
    border: 1px solid #8b8a8b;
    border-radius: 0.25em;
    width: 100%;
    line-height: 1.25rem;
    cursor: pointer;
}

.transcript {
    padding: 0.5rem;
}

.error-msg {
    margin: 0.5rem 0;
    padding: 0.25rem;
    border-radius: 3px 3px 3px 3px;
    color: #D8000C;
    background-color: #FFBABA;
}

/* Popup container */
.popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

/* The actual popup (appears on top) */
.popup .popup-text {
    visibility: hidden;
    width: 20rem;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 0;
    margin-left: -5rem;
}

/* Popup arrow */
.popup .popup-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 54%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

/* Toggle this class when clicking on the popup container (hide and show the popup) */
.popup .show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity:1 ;}
}

.in-circle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #4ab7b6;
    color: #fff;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    text-align: center;
    font-size: 0.8rem;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 2.5rem;
    height: 1.5rem;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 1rem;
    width: 1rem;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .2s;
    transition: .2s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(1rem);
    -ms-transform: translateX(1rem);
    transform: translateX(1rem);
}

/* Rounded sliders */
.slider.round {
    border-radius: 1.5rem;
}

.slider.round:before {
    border-radius: 50%;
}

.transcript .highlight,.autoscroll {
    padding: 0.5rem;
}
.transcript .autoscroll .label,
.transcript .highlight .label {
    margin-right: 1rem;
}

/* desktop layout */
@media only screen and (min-width: 768px) {
    .app {
        width: 100%;
        padding: 0.5rem;
        flex-direction: row;
        overflow: hidden;
        box-sizing: border-box;
    }

    .app-logo {
        height: 25vmin;
    }

    .app .header-select {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        overflow: hidden;
    }

    .app .app-header {
        /*width: unset;*/
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .app .app-header .app-logo {
        padding: 0;
    }

    .app .app-header .app-name {
        padding: 0 1rem;
        margin: 0;
    }

    .transcript-section {
        /*flex: 1 1 auto;*/
        width: 50%;
        /*min-height: 0;*/
        /*max-height: inherit;*/
        overflow-y: auto;
        height: 90vh;
    }

    .transcript .controls {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .transcript-section .content {
        text-align: left;
    }
}

